/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {



	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.

	var windowW,
		windowH,
		$window = $(window),
		isMobile = (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent));



	var Sage = {
		// All pages
		'common': {
			init: function() {
				// JavaScript to be fired on all pages
				globals();
				navigation();
				swiper.init();
				simpleParallax();
				galleryGrid();
				waypoint();
                // menuWork();
					// var s = skrollr.init();
			},
			finalize: function() {
				// JavaScript to be fired on all pages, after page specific JS is fired
			  	$('.js--main-logo').each(function () {
			  		var $img = $(this);
			  		var gifSource = $img.attr('src');

				  	$img.attr('src', "");
				  	$img.attr('src', gifSource + "?"+new Date().getTime());
			  	});
			  	
				window.requestAnimFrame = (function(){
				return  window.requestAnimationFrame       ||
								window.webkitRequestAnimationFrame ||
								window.mozRequestAnimationFrame    ||
								window.oRequestAnimationFrame      ||
								window.msRequestAnimationFrame     ||
								function(/* function */ callback, /* DOMElement */ element){
									window.setTimeout(callback, 1000 / 60);
								};
			})();

			}
		},
		// Home page
		'home': {
			init: function() {
				// JavaScript to be fired on the home page
				// $("body").addClass("lock").delay(10000).queue(function(next){
				// 	$(this).removeClass("lock");
				// });

				// var master = new TimelineMax({delay:1.2}),
				// _isOldIE = (document.all && !document.addEventListener);

				//build master timeline with nested scenes...
				// master.add( one() )
				// .add( two(), "-=0.1")
				// .add( three(), "+=0.25")
				// .add( four(), "+=0.25");
				// .add( five(), "-=0.5");
				
				// function one() {
				//   var tl = new TimelineLite(),
				//       text = $(".header-primary__fade-in--css.one"),
				//       split = new SplitText(".header-primary__fade-in--css.one", {type:"chars,words"}),
				//       chars = split.chars,
				//       centerIndex = Math.floor(chars.length / 2),
				//       i;
				//   for (i = 0; i < chars.length; i++) {
				//     tl.from(chars[i], 0.9, {x:(i - centerIndex) * 40, opacity:0, ease:Power2.easeOut}, i * 0.1);
				//   }
				//   tl.fromTo(text, 1.5, {z:500, y:74, visibility:"visible"}, {z:-1000, ease:SlowMo.ease.config(0.1, 0.9)}, 0);
				//   return tl;
				// }

				// function two() {
				// 	var tl = new TimelineLite(),
				// 		anything = $(".header-primary__fade-in--css.two");
				// 	TweenLite.set([anything], {autoAlpha:0});
				// 	tl.to([anything], 0.9, {autoAlpha:1});
				// 	tl.to(anything, 0.5, {scrambleText:{text:"Qualified by Results", revealDelay:0.25, tweenLength:false}}, 0);
				// 	return tl;
				// }

				// function three() {
				// 	var tl = new TimelineLite(),
				// 		box = $(".header-primary__fade-in--css.five");
				// 	TweenLite.set(box, {scale:0.1, rotation:0.1, autoAlpha:0});
				// 	tl.to(box, 0.3, {autoAlpha:1});
				// 	tl.to(box, 0.3, {scale:1, ease:Linear.easeNone, autoRound:false}, 0);
				// 	tl.to(box, 0.5, {rotation:360.2}, 0.5);
				// 	// tl.to(box, 1.5, {rotationX:360, ease:Elastic.easeOut}, 3.5);
				// 	return tl;
				// }

				// function four() {
				// 	var tl = new TimelineLite(),
				// 		split = new SplitText(".header-primary__fade-in--css.three", {split:"words"}),
				// 		transformSub = $(".header-primary__fade-in--css.four");
				// 		scale = split.words[0],
				// 		rotate = split.words[1],
				// 		move = [split.words[2], split.words[3]];
				// 	TweenLite.set(split.words, {autoAlpha:0, rotationX:-90});
				// 	tl.to(scale, 0.5, {autoAlpha:1, rotationX:0, transformOrigin:"50% 50% -35px"}, 0);
				// 	tl.to(rotate, 0.5, {autoAlpha:1, rotationX:0, transformOrigin:"50% 50% -35px"}, 0.75);
				// 	tl.to(move, 0.5, {autoAlpha:1, rotationX:0, transformOrigin:"50% 50% -35px"}, 1.5);
				// 	tl.from(transformSub, 0.5, {autoAlpha:0}, 2.5);
				// 	return tl;
				// }
			},
			finalize: function() {
				scrollToSection();
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function() {
				// JavaScript to be fired on the about us page
			}
		},
		'contact': {
			init: function() {
				 contactForm();
			}
		},
		'international_school_of_brooklyn': {
			init: function() {

			},
			finalize: function () {
				siteBuildAnimation();
			}
		},
		'amazing_grass': {
			init: function() {

			},
			finalize: function() {
			}
		}
	};


	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function(func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function() {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);
		var globals = function() {
			windowW = window.innerWidth;
			windowH = window.innerHeight;
            var windowWidthInit = window.innerWidth;

			$('body').addClass('loaded');

			var body = document.querySelectorAll('body')[0];
			var uiHidden = false;
			var timeout;

			$(window).on('resize', function() {
				windowW = window.innerWidth;
				windowH = window.innerHeight;
                if ( isMobile && windowWidthInit !== windowW && windowW <= 768 ) {
                    mobileSectionHeight();

                    windowWidthInit = windowW;
                }
			});

			// body.addEventListener('wheel', mobileNav);
            //
			// function mobileNav(e) {
            //
			// 	var delta = Math.max(-1, Math.min(1, (e.wheelDelta || -e.detail)));
			// 	if(delta < 0 && !uiHidden) {
			// 		body.classList += ' scrolling';
			// 		uiHidden = true;
            //
				// 	clearTimeout(timeout);
				// 	timeout = setTimeout(function () {
				// 		$(body).removeClass('scrolling');
				// 		uiHidden = false;
				// 	}, 2000);
			// 	}
			// 	if(delta > 0 && uiHidden) {
			// 		$(body).removeClass('scrolling');
			// 		uiHidden = false;
            //
			// 	}
			// 	// console.log(delta);
			// }

            body.addEventListener('touchstart', touchStart, false);

            var start = {x:0,y:0},
                newStart;

            function touchStart(e) {
                start.y = event.touches[0].pageY;

                if ( start.y > 0 ) {

                    if ( !$('body').hasClass('nav-open') ) {
                        $('.header').addClass('scrolling');

                    }else if ( windowW > 768 ) {
                        // e.preventDefault();
                        // e.stopPropagation();
                    }

                    clearTimeout(timeout);
                    timeout = setTimeout(function () {
                        $('.header').removeClass('scrolling');
                    }, 2000);
                }

                if ( newStart > start.y ) {
                    $('.header').removeClass('scrolling');
                }

                newStart = start.y;
            }

            if ( isMobile ) {
                $('.footer').removeClass('scrollme animateme').removeAttr('data-translatey');

                mobileSectionHeight();
            }

            function mobileSectionHeight() {
                if ( windowW > 768 ) {
                    $('.nav-works').height(window.innerHeight);
                }

                $('.nav-primary').height(window.innerHeight);
				if(windowW < windowH) // Portrait
				{
					$('.case-preview--varH').height(window.innerHeight*1.25);
				}
				if(windowW > windowH)
				{
					$('.case-preview--varH').height(window.innerHeight*1.5);
				}
            }
		};

	var navigation = function() {
		var btn = $('.menu-btn, .nav-header-mob'),
			nav = $('.nav-primary'),
			navOpen = false;

		btn.on('click', function () {
				if(!navOpen) {
					$('body').addClass('nav-open');
                    $('.header').removeClass('scrolling');
                    if ( isMobile ) {
                        $(window).on('resize', function() {
                            $('.header').height(window.innerHeight);
                        }).trigger('resize');
                    }
					navOpen = true;
				}
				else {
					$('body').removeClass('nav-open');
                    $('.header').removeClass('scrolling');
					navOpen = false;
				}
		});


		$(document).keyup(function(e) {
			if (e.keyCode === 27) {
					$('body').removeClass('nav-open');
					navOpen = false;
			}
		});

	};
		var swiper = (function () {

			var mySwiper,
					run = false,
					container = $('.swiper-container');

			function init () {
				if(container.length) {

					if(container.is('.big-slider')) {
						bigSwiper = new Swiper('.big-slider', {
							grabCursor: true,
								slidesPerView: 1,
								initialSlide: 0,
								loop: true,
								pagination: '.slider-dots',
								paginationClickable: true,
								centeredSlides: true,
								nextButton: '.slider-ar.right',
								prevButton: '.slider-ar.left',
								onSlideChangeStart: function (swiper) {
									var slideIndex = (swiper.activeIndex - 1) % (swiper.slides.length - 2);
									var wdSlideCopy = $('body').find('.wd-slider__slide-copy');
									if (wdSlideCopy.length) {
										wdSlideCopy.children().fadeOut();
										var wdSlideCopyItem = wdSlideCopy.children().eq(slideIndex);
										wdSlideCopyItem.fadeIn();
									}
								}

						});
					}
					else{
						enable();
					}
					$(window).on('resize', function () {
							resize();
					}).trigger('resize');
				}
			}

			function enable() {
					if(!run && windowW < 768) {

						container.each(function (e, i) {
							var space = $(this).data('space-between') || 0,
												paginaiton = $(this).find('.slider-dots');

										if ( $(this).is('.swiper-container_full') ) {
												paginaiton = $(this).next('.slider__bottom').find('.slider-dots');
										}

							mySwiper = new Swiper($(this)[0], {
										spaceBetween: space,
										grabCursor: true,
										slidesPerView: 1,
										initialSlide: 0,
										centeredSlides: true,
										pagination: paginaiton,
								paginationClickable: true,
								onInit: function (swiper) {
									swiper.update();
								},
								onSlideChangeEnd: function (swiper) {
												swiper.update();
										}
								});
						});



							run = true;
					}else if (!run && windowW > 768) {

								mySwiper = new Swiper('.swiper-container', {
										grabCursor: true,
										slidesPerView: 1,
										initialSlide: 0,
										loop: true,
										paginationClickable: true,
										centeredSlides: true,
										nextButton: '.slider-arrow.right',
										prevButton: '.slider-arrow.left',
								});

								run = true;
						}

			}


			function destroy (argument) {
					if(run) {
						console.log('destroy swiper');
						container.each(function(i) {
							$(this)[0].swiper.destroy(false, true);
								run = false;
						});

					}

			}

			function resize (argument) {
					if(windowW <= 768) {

							swiper.enable();
					}
					else {
							swiper.destroy();
					}
			}

			return{
					init :init,
					destroy: destroy,
					resize: resize,
					enable: enable
			};
	})();

	var contactForm = function () {
		var selects = $('.select');

		if( selects.length) {

			selects.selectivity({
				allowClear: false,
				multiple: false,
				showSearchInputInDropdown: false,
				templates: {
					singleSelectInput: (
								'<div class="single-select">' +
										'<input type="text" class="selectivity-single-select-input">' +
										'<div class="selectivity-single-result-container"></div>' +
										'<i class="fa fa-sort-desc selectivity-caret"></i>' +
								'</div>'
						)
				}

			});

			$('body').on('click', function(e) {

				selects.selectivity('close');
				/* Act on the event */
			});
		}
	};
	var scrollToSection = function() {
		$('.scroll-arrow').on('click', function(e) {
			$("body").removeClass("lock");
			var elementOffset = $(this).closest('section').next().offset().top;
			
			scrollTo(elementOffset, 1000);
		});

		function scrollTo(to, duration, callback) {
			var start = position(),
								change = to - start,
								currentTime = 0,
								increment = 20;
								duration = (typeof(duration) === 'undefined') ? 500 : duration;


						Math.easeInOutQuad = function (t, b, c, d) {
								t /= d/2;
								if (t < 1) {
										return c/2*t*t + b;
								}
								t--;
								return -c/2 * (t*(t-2) - 1) + b;
						};

						function move(amount) {
								// difficult to detect the scrolling element, just move them all
								document.documentElement.scrollTop = amount;
								document.body.parentNode.scrollTop = amount;
								document.body.scrollTop = amount;
						}
						function position() {
								return document.documentElement.scrollTop || document.body.parentNode.scrollTop || document.body.scrollTop;
						}



						function animateScroll(){
								// increment the time
								currentTime += increment;
								// find the value with the quadratic in-out easing function
								var val = Math.easeInOutQuad(currentTime, start, change, duration);
								// move the document.body
								move(val);
								// do the animation unless its over
								if (currentTime < duration) {
										requestAnimFrame(animateScroll);
								}
								else {
										if (callback && typeof(callback) === 'function') {
												// the animation is done so lets callback
												callback();
										}
								}
						}
						animateScroll();
		}
	};

	var waypoint = function() {
		var wpItem = $('.wp-item ');
		var backItems = $('.ag-product-img');

		var waypoint =  wpItem.waypoint({
				handler: function(direction) {
						$(this.element).removeClass('charged');
				},
				offset: "75%"
		});

		if(backItems.length) {
			var newWaypoint =  backItems.waypoint({
				handler: function(direction) {
					backItems.each(function(index, el) {
					setTimeout(function () {
							$(el).addClass('show');
						}, index * 100);

					});
					setTimeout(function () {
						$('.ag-bar').addClass('show');
					}, backItems.length * 100);
				},
				offset: "100%"
			});
		}

	};

	var simpleParallax = function() {
				var item = $('.parallax-cont section, .pp-web__quote__bg'),
						height = item.height();

				item.parent().height(height - 70);

				if ( $(window).width() >= 970 ) {
						$(window).scroll(function(){
								item.each(function(){
										var pos = $(this).offset().top;
										var scrolled = pos - $(window).scrollTop();

										$(this).css({
												"-webkit-transform":"translate3d(0px,"+ (scrolled * 0.1) +"px,0px)",
												"-ms-transform":"translate3d(0px,"+ (scrolled * 0.1)+"px,0px)",
												"transform":"translate3d(0px,"+ (scrolled * 0.1) +"px,0px)"
										});
								});
						});
				}
	};

	var galleryGrid = function() {
				// $('.cb-gallery__inner').masonry({
				//
				//     itemSelector: '.cb-gallery-item',
				//     columnWidth: 10,
				//     gutter: 15,
				//     resize: true
				// });

				function resize() {
						if ( $(window).width() <= 970 ) {
								var itemHeight = $('.cb-gallery-item').height() / 2;

								$('.cb-gallery-item_width2').height(itemHeight - 7.5);

						}
				}

				$(window).on('resize, load', function() { resize(); });

				$(window).on('load', function() { resize(); });
	};



	var siteBuildAnimation = function () {
		$('.isb-symbol').waypoint({
			handler: function (direction) {
				setTimeout(function () {
					$('.isb-symbol img')
						.css('left', '0')
						.attr('src', $('.isb-symbol img').attr('src') + Time.now());
				}, 250);
			},
			offset: "75%"
		});

		var scene = $('.animation-scene');
		var imgHolder = $('.img-placeholder');
		var holderUrl = scene.data('img-placeholder');
		var enable = false,
			build = false,
			unitConfig = {},
			scenseSize = 0;


		if(windowW > 970) {
			// init(function() {
			// 	alignUnits(document.querySelectorAll('.scene-unit'));
			// });
			init();
		}

		$window.on('resize', function() {
			windowW = document.innerWidth;
			resize();
		});

		function resize() {

			if(windowW > 970) {

				// init(function() {
				// 	alignUnits(document.querySelectorAll('.scene-unit'));
				// });
				init();


			}
			else {
				destroy();
			}
		}

		function init(callback) {
			if(!enable) {
				console.log('initialize animation');
				enable = true;
				makeImg();
			}
			if(callback) {
				callback();
			}

		}
		function destroy() {
			if(enable) {
				console.log('destroy animation scene');
				scene.removeClass('proceed');
				enable = false;
			}

		}

		function makeImg() {


			if(!build) {
				var sceneRow = $('.scene-row');

				sceneSize = sceneRow.width();

				sceneRow.each(function(index, el) {
					var unit = $(this).find('.scene-unit');
					el.dataset.units = unit.length;

					unit.each(function(index, el) {
						var imgSrc = this.dataset.src;
						var img = new Image();

						img.src = imgSrc;

						img.onload = function() {
							var width = this.naturalWidth,
								height = this.height;

						    img.width = width;
						    img.style.width = 'initial';
						    el.appendChild(img);
						};

						el.dataset.index = index;
					});
				});


				var waypoint = scene.waypoint({
					handler: function(direction) {
						setTimeout(function () {
							scene.addClass('proceed');
						}, 400);

					},
					offset: "75%"
				});



				build = true;
			}

		}
		// function alignUnits(el) {
		// 	for (var i = 0; i < el.length; i++) {
		// 		var length = el[i].parentElement.dataset.units;
		// 		var offsetLeft = sceneSize / length * el[i].dataset.index;
		// 		el[i].style.left = offsetLeft + 'px';
		// 	}
		// }
	};

	var menuWork = function() {
        var bigNav = $('.nav-works.big li'),
            smallNav = $('.nav-works.small li');

        smallNav.each( function() {
            if ( $('body').hasClass( $(this).data('id') ) ) {
                smallNav.removeClass('hidden');
                bigNav.removeClass('active');
                $(this).addClass('hidden');
                bigNav.eq($(this).index()).addClass('active');

            }
        });
	};

})(jQuery); // Fully reference jQuery after this point.




